import WOW from 'wowjs';
import Slider from "react-slick";
import EventBus from 'eventing-bus';
import Modal from '@mui/material/Modal';
import AudioPlayer from 'react-h5-audio-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState, useRef } from 'react';

import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const tracks = [
    { url: "https://assets-softtik.s3.us-east-2.amazonaws.com/The+Upbeat.mp3" },
    { url: "https://assets-softtik.s3.us-east-2.amazonaws.com/Small+Dog+Bark+4.mp3" },
]
const address = 'TBqaBmfgn1u19qe4pkh8Siff2PNMHbXovE';
const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 9000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    rtl: true,
};

const Home = () => {
    const player = useRef();
    const [trackIdx, setTrackIdx] = useState(0);
    const [isEnterModal, setIsEnterModal] = useState(true);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);
    //Check Website Loaded!
    useEffect(() => {
        document.onreadystatechange = function () {
            if (document.readyState === 'complete') setTimeout(() => setIsPageLoaded(true), 1500);
        }
    }, []);

    const handleEnterWebsite = () => {
        window.scrollTo(0, 0)
        setIsEnterModal(false);
        player?.current?.audio?.current.play();
    };

    return (
        <div className='tron-dog-page'>
            <div>
                <Navbar />
                {/* Banner */}
                <section className="banner-section" id="home">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className='banner-left-area'>
                                    <div className='left-text-area'>
                                        <h2 style={{'paddingBottom': '60px', 'paddingTop': '40px'}}>DOGGO</h2>
                                        <button className='join-btn'>Buy Now</button>
                                    </div>
                                    <div className='right-dog' onMouseEnter={() => setTrackIdx(1)} onMouseLeave={() => setTrackIdx(0)}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='audio-plyer'>
                        <AudioPlayer
                            ref={player}
                            showSkipControls
                            autoPlay
                            showJumpControls={false}
                            autoPlayNextTrack={true}
                            src={tracks[trackIdx].url}
                            showFilledVolume={true}
                        />
                    </div>
                </section>
                <div className='bottom-animation-area'>
                    <div className='img'><img src={require("../../static/images/banner-bottom-bg.png")} alt="" /></div>
                    <Slider {...settings} className='wow fadeInLeft'>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                    </Slider>
                </div>

                {/* About */}
                <section className="about-section" id="about">
                    <div className="auto-container">
                        <div className="row">
                            <div className='col-lg-6 col-md-12'>
                                <div className='about-img  wow zoomIn'>
                                    <img className='pe-auto' onMouseEnter={() => setTrackIdx(1)} onMouseLeave={() => setTrackIdx(0)} src={require("../../static/images/about-img.png")} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='about-text'>
                                    <h2 className=' wow zoomIn'><span>Tale of DOGGO</span> </h2>
                                    <p className=' wow fadeInRight'>$DOGGO, a playful dog with big dreams, created $DOGGO to bring fun to the TRON community. With every wag and cute glance, Neiro backs your TRON dreams! 🔥🚀</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='bottom-animation-area'>
                    <div className='img'><img src={require("../../static/images/banner-bottom-bg.png")} alt="" /></div>
                    <Slider {...settings} className='wow fadeInLeft'>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                    </Slider>
                </div>

                {/* Coming Soon */}
                <section className="coming-section" id="coming">
                    <div className="coming-soon">
                        <img src="https://assets-softtik.s3.us-east-2.amazonaws.com/tron-dog-animation.gif" alt="" />
                    </div>
                </section>

                <div className='bottom-animation-area'>
                    <div className='img'><img src={require("../../static/images/banner-bottom-bg.png")} alt="" /></div>
                    <Slider {...settings} className='wow fadeInLeft'>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                    </Slider>
                </div>

                {/* Toknomics Section */}
                <section className='toknomics-sec' id="toknomics-sec">
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-7'>
                                <div className='sec-title wow zoomIn'>
                                    <h2><span><img src={require("../../static/images/toknomics-title.png")} alt="" /></span></h2>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInLeft'>
                                            <div className='text-box' style={{ marginTop: '35px' }}>
                                                <h4>Name</h4>
                                                <p style={{ fontSize: '29px' }}>DOGGO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInLeft'>
                                            <div className='text-box'>
                                                <CopyToClipboard text={address} onCopy={() => EventBus.publish('success', 'Address Copied!')}>
                                                    <button className='icon'><i><img src={require("../../static/images/file.png")} alt="" /></i></button>
                                                </CopyToClipboard>
                                                <h4>Token Address</h4>
                                                <p>{address.substring(0, 5)}.....{address.substring(address.length - 5, address.length)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInDown'>
                                            <div className='text-box'>
                                                <i className='icon'><img src={require("../../static/images/doller.png")} alt="" /></i>
                                                <h4>Total Supply</h4>
                                                <p>100 billion</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <div className='toknomics-box wow fadeInUp'>
                                            <div className='text-box'>
                                                <i className='icon'><img src={require("../../static/images/fire.png")} alt="" /></i>
                                                <h4>Liquidity Burnt</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='bottom-animation-area'>
                    <div className='img'><img src={require("../../static/images/banner-bottom-bg.png")} alt="" /></div>
                    <Slider {...settings} className='wow fadeInLeft'>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                    </Slider>
                </div>

                {/* Buy Section */}
                <section className="buy-section" id="buy">
                    <div className='rocket-img'><img className='pe-auto' onMouseEnter={() => setTrackIdx(1)} onMouseLeave={() => setTrackIdx(0)} src={require("../../static/images/rocket-img.png")} alt="" /></div>
                    <div className='sec-title'>
                        <h2><span>buy now </span></h2>
                    </div>
                    <div className='buy-btn'>
                        <button>BUY ON SUNWAP<i className='icon'><img src={require("../../static/images/buy-btn-icon.png")} alt="" /></i></button>
                    </div>
                </section>

                <div className='bottom-animation-area'>
                    <div className='img'><img src={require("../../static/images/banner-bottom-bg.png")} alt="" /></div>
                    <Slider {...settings} className='wow fadeInLeft'>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                        <div>
                            <p>$DOGGO</p>
                        </div>
                    </Slider>
                </div>

                {/* Join Section */}
                <section className='join-sec' id="joinme">
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <div class="txt wow fadeInUp" id="txt">
                                    <b>J</b><b>O</b><b>I</b><b>N</b><b></b><b>T</b><b>H</b><b>E</b>
                                </div>
                                <div class="txt small-text  wow fadeInDown" id="txt">
                                    <b>C</b><b>O</b><b>M</b><b>M</b><b>U</b><b>N</b><b>I</b><b>T</b><b>Y</b>
                                </div>
                                <div className='sec-title wow zoomIn'>
                                    <ul>
                                        <li>
                                            <a className='nav-link' href='http://x.com/DoggoOfficials' target='_blank'><img src={require('../../static/images/x.png')} alt='' />Twitter</a>
                                        </li>
                                        <li>
                                            <a className='nav-link' href='http://t.me/DoggoOfficials' target="_blank"><img src={require('../../static/images/telegram.png')} alt='' />Telegram</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='img-area'>
                                    <img className='pe-auto' onMouseEnter={() => setTrackIdx(1)} onMouseLeave={() => setTrackIdx(0)} src={require("../../static/images/moon-img.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/* Enter Website */}
            <Modal open={isEnterModal} onClose={() => setIsEnterModal(false)} className='modal-enter-website'>
                <div className='body-modal'>
                    <div className='modal-inner'>
                        <div className='left-text-area' style={{"paddingBottom": '60px'}}>
                            <h1>DOGGO</h1>
                            {/* <h2>DOG</h2> */}
                        </div>
                        {isPageLoaded
                            ? <button className='entry-btn' onClick={() => handleEnterWebsite()}>Start</button>
                            : <i class="loader"><img src={require('../../static/images/spinner.gif')} alt='' /></i>
                        }
                    </div>
                </div>
            </Modal>
        </div >
    );
}

export default Home;